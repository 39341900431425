.container {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  overflow: hidden;
  pointer-events: none;
  color: #232F3E;
  font-family: "Amazon Ember",Arial,sans-serif;
  border: 2px solid #E45F2E;
  background-color: rgba(228,95,46,0);
  transition: background-color 150ms ease-out;
}